import { Box, AppBar, Toolbar, Typography, Divider } from '@mui/material';

export const HeaderNavi = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="absolute"
        elevation={0}
        sx={{
          backgroundColor: '#FFFFFF',
          color: '#000000',
          width: '100%',
          left: '0px',
          top: '0px',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar disableGutters sx={{ height: '100px', paddingLeft: '30px', paddingRight: '20px' }}>
          <Typography
            variant="body1"
            component="div"
            sx={{
              flexGrow: 1,
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '24px',
              lineHeight: '150%',
              letterSpacing: '0.15px',
            }}
          >
            Gain 管理画面
          </Typography>
        </Toolbar>
        <Divider sx={{ height: '1px' }} />
      </AppBar>
    </Box>
  );
};
