import { Box, Typography } from '@mui/material';

export type ContentTitleProps = {
  title: string;
};

export const ContentTitle = (props: ContentTitleProps) => {
  const { title } = props;
  return (
    <Box>
      <Typography
        component="div"
        sx={{
          fontFamily: 'Roboto',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '24px',
          lineHeight: '28px',
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};
