export const LoginFormFields: {
  signIn: any;
} = {
  signIn: {
    username: {
      'data-test': 'username',
      placeholder: 'Eメール',
    },
    password: {
      'data-test': 'password',
      placeholder: 'パスワード',
    },
  },
};
