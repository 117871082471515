import './App.css';

import { AppProvider } from '@/providers/app';
import { AppRoutes } from '@/routes';

import { AxiosInterceptor } from './utils/axiosInstance';

const App = () => (
  <AppProvider>
    <AxiosInterceptor>
      <AppRoutes />
    </AxiosInterceptor>
  </AppProvider>
);

export default App;
