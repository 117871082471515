import { ArrowDropDown } from '@mui/icons-material';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Pagination,
  tablePaginationClasses as classes,
  styled,
  PaginationItem,
} from '@mui/material';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { ListPagination, Order } from '@/hooks/useListPagination';
export type HeadCell<T> = {
  id: keyof T;
  label: string;
  align: 'right' | 'left' | 'center' | 'inherit' | 'justify';
  width?: string;
  sort?: boolean;
  fontSize?: string;
  fontColor?: string;
  tooltip?: boolean;
};

export type RowCell = {
  align: 'right' | 'left' | 'center' | 'inherit' | 'justify';
  width?: string;
};

export type TableHeadSetting = {
  height?: string;
  color?: string;
};

type BasicTableRowCellProps = TableCellProps & {
  align: 'right' | 'left' | 'center' | 'inherit' | 'justify';
  innerWidth?: string | undefined;
  linkTo?: string | undefined;
  isEllipsis?: boolean;
  linkSx?: React.CSSProperties | undefined;
  linkTarget?: '_self' | '_blank' | '_parent' | '_top';
  children: React.ReactNode;
};

export const BasicTableRowCell = (props: BasicTableRowCellProps) => {
  const { align, innerWidth, linkTo, linkSx, isEllipsis, linkTarget, children, ...other } = props;

  const StyledTypography = () => {
    return (
      <Box
        width={innerWidth}
        sx={{
          paddingLeft: align === 'center' ? undefined : '24px',
          paddingRight: align === 'right' ? '30px' : undefined,
        }}
      >
        {isEllipsis ? (
          <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {children}
          </Typography>
        ) : (
          <Typography>{children}</Typography>
        )}
      </Box>
    );
  };

  return (
    <TableCell align={align} padding="none" {...other}>
      {linkTo ? (
        <Link
          style={linkSx}
          to={linkTo}
          target={linkTarget}
          rel={linkTarget === '_blank' ? 'noopener noreferrer' : undefined}
          onClick={(event) => event.stopPropagation()}
        >
          <StyledTypography />
        </Link>
      ) : (
        <StyledTypography />
      )}
    </TableCell>
  );
};
BasicTableRowCell.defaultProps = {
  innerWidth: undefined,
  linkTo: undefined,
  isEllipsis: true,
};

type NotFoundDataProps = {
  colCount: number;
  children: React.ReactNode;
};

const NotFoundData = ({ colCount, children }: NotFoundDataProps) => {
  return (
    <TableRow sx={{ height: '188px' }}>
      <TableCell colSpan={colCount} padding="none">
        <Box>
          <Typography
            sx={{
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '20px',
              lineHeight: '22px',
              color: '#808080',
              textAlign: 'center',
            }}
          >
            {children}
          </Typography>
        </Box>
      </TableCell>
    </TableRow>
  );
};

type TableLayoutHeadProps<T> = {
  headCells: HeadCell<T>[];
  order: Order;
  orderBy: keyof T;
  onRequestSort: (event: React.MouseEvent<unknown>, newOrderBy: keyof T) => void;
  headCellSetting?: TableHeadSetting;
};

const TableLayoutHead = <T extends { listIndex: number }>(props: TableLayoutHeadProps<T>) => {
  const { headCells, order, orderBy, onRequestSort, headCellSetting } = props;
  const createSortHandler = (newOrderBy: keyof T) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, newOrderBy);
  };

  return (
    <TableHead
      sx={{
        background: headCellSetting?.color ? headCellSetting.color : '#E5E7EB',
        height: headCellSetting?.height ? headCellSetting.height : '60px',
      }}
    >
      <TableRow>
        {headCells.map((headCell, index) => {
          const id = index;
          return (
            <TableCell
              key={id}
              align={headCell.align}
              padding={headCell.align === 'center' ? 'none' : 'normal'}
              width={headCell.width}
              sx={{
                fontSize: headCell.fontSize ? headCell.fontSize : '16px',
                lineHeight: '28px',
                paddingLeft: headCell.align === 'center' ? undefined : '24px',
                color: headCell.fontColor,
              }}
            >
              {headCell.sort ? (
                <TableSortLabel
                  IconComponent={ArrowDropDown}
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              ) : (
                <>{headCell.label}</>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export const DEFAULT_ROWS_PER_PAGE = 30;
export const DEFAULT_ROW_HEIGHT = '52px';

export type TableLayoutProps<T> = {
  headCells: HeadCell<T>[];
  rows: T[];
  totalCount?: number;
  useListPage: ListPagination<T>;
  isPagenation?: boolean;
  tableMaxHeight?: string | undefined;
  messageNotFound?: string;
  children: React.ReactNode;
  headCellSetting?: TableHeadSetting;
};

export const TableLayout = <T extends { listIndex: number }>(props: TableLayoutProps<T>) => {
  const {
    headCells,
    rows,
    totalCount,
    useListPage,
    isPagenation,
    tableMaxHeight,
    messageNotFound,
    children,
    headCellSetting,
  } = props;
  const { order, setOrder, orderBy, setOrderBy, page, setPage, rowsPerPage, setRowsPerPage, setVisibleRows } =
    useListPage;
  const total = totalCount ? totalCount : rows.length;

  React.useEffect(() => {
    setVisibleRows(rows);
  }, [rows, setVisibleRows]);

  const handleRequestSort = React.useCallback(
    (event: React.MouseEvent<unknown>, newOrderBy: keyof T) => {
      const isAsc = orderBy === newOrderBy && (order === 'asc' || !order);
      const toggledOrder = isAsc ? 'desc' : 'asc';
      setOrder(toggledOrder);
      setOrderBy(newOrderBy);
    },
    [orderBy, order, setOrder, setOrderBy]
  );

  const handleChangePage = React.useCallback(
    (event: unknown, newPage: number) => {
      setPage(newPage);
    },
    [setPage]
  );

  const handleChangeRowsPerPage = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const updatedRowsPerPage = parseInt(event.target.value, 10);
      setRowsPerPage(updatedRowsPerPage);
      setPage(1);
    },
    [setPage, setRowsPerPage]
  );

  const handleChangeTablePage = React.useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <Paper variant="outlined" sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: tableMaxHeight }}>
          <Table
            aria-labelledby="tableTitle"
            sx={{
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '20px',
              color: '#111827',
            }}
          >
            <TableLayoutHead
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCellSetting={headCellSetting}
            />
            <TableBody>
              {total > 0 ? <>{children}</> : <NotFoundData colCount={headCells.length}>{messageNotFound}</NotFoundData>}
            </TableBody>
            {isPagenation && total > 0 && (
              <TableFooter sx={{ height: '60px' }}>
                <TableRow>
                  <CustomTablePagination
                    colSpan={headCells.length}
                    rowsPerPageOptions={[30, 50, 100]}
                    count={total}
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage="1ページあたりの行数:"
                    page={page - 1}
                    onPageChange={handleChangeTablePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={() => (
                      <Pagination
                        boundaryCount={0}
                        siblingCount={3}
                        count={Math.ceil(total / rowsPerPage)}
                        page={page}
                        shape="rounded"
                        variant="outlined"
                        onChange={handleChangePage}
                        renderItem={(item) => (
                          <PaginationItem
                            slots={{ first: KeyboardDoubleArrowLeftIcon, last: KeyboardDoubleArrowRightIcon }}
                            {...item}
                          />
                        )}
                        showFirstButton
                        showLastButton
                        sx={{ ml: '10px', margin: '0 0 0 auto', paddingRight: '10px' }}
                      />
                    )}
                  />
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};
TableLayout.defaultProps = {
  isPagenation: true,
  tableMaxHeight: undefined,
  messageNotFound: '該当データはありません',
};
const CustomTablePagination = styled(TablePagination)`
  & .${classes.input} {
    margin: 0;
  }

  & .${classes.spacer} {
    display: none;
  }
`;
