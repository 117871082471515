import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';

import storage from '@/utils/storage';

import { LoginFormFields, LoginComponents, LoginVocabularies } from '../components';

import '@aws-amplify/ui-react/styles.css';

type LoginSettingProps = {
  isHideSignUp?: boolean;
};

type LoginProps = {
  children: React.ReactNode;
  isHideSignUp?: boolean;
};

const LoginSetting = ({ isHideSignUp = false }: LoginSettingProps) => (
  <Authenticator formFields={LoginFormFields} components={LoginComponents} hideSignUp={isHideSignUp} />
);
LoginSetting.defaultProps = {
  isHideSignUp: false,
};

const LoginCheck = (props: LoginProps) => {
  const { children, isHideSignUp } = props;
  const { route, user } = useAuthenticator((context) => [context.route]);

  const token = user?.getSignInUserSession()?.getAccessToken().getJwtToken() ?? '';
  storage.setToken(token);
  return route === 'authenticated' ? <>{children}</> : <LoginSetting isHideSignUp={isHideSignUp} />;
};
LoginCheck.defaultProps = {
  isHideSignUp: false,
};

// const CustomUsernameField = () => {
//   return <TextField type="email" label="Eメール" name="username" placeholder="Eメールを入力してください" required />;
// };

export const Login = (props: LoginProps) => {
  LoginVocabularies('Ja');
  const { children, isHideSignUp } = props;
  return (
    <Authenticator.Provider>
      <LoginCheck isHideSignUp={isHideSignUp}>{children}</LoginCheck>
    </Authenticator.Provider>
  );
};
Login.defaultProps = {
  isHideSignUp: false,
};
