import { styled } from '@mui/material';
import Alert from '@mui/material/Alert';
import Snackbar, { SnackbarCloseReason, SnackbarOrigin } from '@mui/material/Snackbar';
import React, { createContext, useState, useContext, FC, ReactNode } from 'react';

type SnackbarSeverity = 'error' | 'warning' | 'info' | 'success';

type props = {
  children: ReactNode;
};

interface ISnackbarContext {
  showSnackbar: ((type: SnackbarSeverity, message: string) => void) | undefined;
}

const SnackbarContext = createContext<ISnackbarContext>({ showSnackbar: undefined });

export function useSnackbarContext() {
  return useContext(SnackbarContext);
}

export const SnackbarProvider: FC<props> = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [severity, setSeverity] = useState<SnackbarSeverity>('info');
  const [message, setMessage] = useState<string>('');
  const [anchorOrigin, setAnchorOrigin] = useState<SnackbarOrigin>();

  const showSnackbar = (
    type: SnackbarSeverity,
    message: string,
    anchorOrigin: SnackbarOrigin = { vertical: 'top', horizontal: 'center' }
  ): void => {
    setOpen(true);
    setSeverity(type);
    setMessage(message);
    setAnchorOrigin(anchorOrigin);
  };

  const handleClose = (_event: React.SyntheticEvent<any> | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <React.Fragment>
      <SnackbarContext.Provider value={{ showSnackbar: showSnackbar }}>{children}</SnackbarContext.Provider>
      <Snackbar anchorOrigin={anchorOrigin} open={open} autoHideDuration={5000} onClose={handleClose}>
        <div>
          <CustomAlert
            severity={severity}
            sx={{
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px',
              backgroundColor: '#f44336',
              color: '#fdeded',
              fontWeight: 'bold',
              width: '500px',
              whiteSpace: 'pre-line',
            }}
          >
            通信エラーが発生しました。{message}
          </CustomAlert>
          <CustomAlert
            severity={severity}
            sx={{
              borderBottomLeftRadius: '8px',
              borderBottomRightRadius: '8px',
              color: '#f44336',
              fontSize: '11px',
              width: '500px',
              whiteSpace: 'pre-line',
            }}
          >
            しばらく時間をおいて再度お試しください。
          </CustomAlert>
        </div>
      </Snackbar>
    </React.Fragment>
  );
};

const CustomAlert = styled(Alert)(() => ({
  borderRadius: '0',
  '& .MuiAlert-icon': { display: 'none' },
}));
