import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export type ContentLinkProps = {
  name: string;
  to: string;
};

export const ContentLink = (props: ContentLinkProps) => {
  const { to, name } = props;
  return (
    <Link to={to}>
      <Typography
        sx={{
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '20px',
          lineHeight: '28px',
          color: '#0288D1',
          textDecoration: 'none',
        }}
      >
        {name}
      </Typography>
    </Link>
  );
};
