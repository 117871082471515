import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';

import { Button } from '@/components/Elements/Button';
import { queryClient } from '@/lib/react-query';

import { SnackbarProvider } from './snackbarProvider';

const ErrorFallback = () => (
  <div className="text-red-500 w-screen h-screen flex flex-col justify-center items-center" role="alert">
    <h2 className="text-lg font-semibold">Ooops, something went wrong :( </h2>
    <Button className="mt-4" onClick={() => window.location.assign(window.location.origin)}>
      Refresh
    </Button>
  </div>
);

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => (
  <React.Suspense fallback={<div className="flex items-center justify-center w-screen h-screen" />}>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <HelmetProvider>
        <SnackbarProvider>
          <QueryClientProvider client={queryClient}>
            {process.env.NODE_ENV !== 'test' && <ReactQueryDevtools />}
            <Router>{children}</Router>
          </QueryClientProvider>
        </SnackbarProvider>
      </HelmetProvider>
    </ErrorBoundary>
  </React.Suspense>
);
