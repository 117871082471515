import { Box } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { ContentBreadcrumbs, ContentBreadcrumbsProps } from './ContentBreadcrumbs';
import { ContentLink, ContentLinkProps } from './ContentLink';
import { ContentTitle, ContentTitleProps } from './ContentTitle';

export type ContentLayoutProps = {
  width?: string;
  height?: string;
  background?: string;
  titleProps?: ContentTitleProps;
  breadcrumbsProps?: ContentBreadcrumbsProps;
  linkDecoration?: boolean;
  linkSize?: string;
  linkProps?: ContentLinkProps;
  children?: React.ReactNode;
};

export const ContentLayout = (props: ContentLayoutProps) => {
  const { width, height, background, titleProps, breadcrumbsProps, linkProps, linkDecoration, linkSize, children } =
    props;

  return (
    <Box id="content_layout" sx={{ width: width, height: height, background: background }}>
      {breadcrumbsProps && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            position: 'absolute',
            height: '50px',
            top: '126px',
            left: '341px',
          }}
        >
          <ContentBreadcrumbs breadcrumbsItems={breadcrumbsProps.breadcrumbsItems} />
        </Box>
      )}
      {titleProps && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            paddingTop: '5px',
            paddingLeft: '50px',
            position: 'absolute',
            height: '38px',
            top: '181px',
          }}
        >
          <ContentTitle title={titleProps.title} />
        </Box>
      )}
      {linkProps && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            paddingLeft: '50px',
            position: 'absolute',
            left: linkSize === '14px' ? '1240px' : '1172px',
            top: '224px',
            a: {
              textDecoration: linkDecoration ? 'underline' : 'none',
              '.MuiTypography-root': {
                fontSize: linkSize,
              },
            },
          }}
        >
          <ContentLink name={linkProps.name} to={linkProps.to} />
        </Box>
      )}
      <Box component="main">
        <LocalizationProvider dateAdapter={AdapterDayjs}>{children}</LocalizationProvider>
      </Box>
    </Box>
  );
};
ContentLayout.defaultProps = {
  width: '1142px',
  height: '1315px',
  background: '#FFFFFF',
  linkProps: {},
  children: null,
};
