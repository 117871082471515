/* eslint-disable import/no-extraneous-dependencies */
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';

import { useSnackbarContext } from '@/providers/snackbarProvider';
import storage from '@/utils/storage';

const baseURL = process.env.REACT_APP_API_URL;
export const axiosInstance = axios.create({
  baseURL,
});

export const AxiosInterceptor = ({ children }: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const navigate = useNavigate();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { showSnackbar } = useSnackbarContext();

  useEffect(() => {
    const resInterceptor = (response: any) => {
      return response;
    };

    const errInterceptor = (error: { response: { status: any; data: any } }) => {
      if (!error.response) {
        showSnackbar?.('error', '');
      } else {
        const status = error.response?.status;
        switch (status) {
          case 400:
            showSnackbar?.('error', '(F)');
            break;
          case 401:
            showSnackbar?.('error', '(F)');
            break;
          case 500:
            showSnackbar?.('error', '(B)');
            break;
          default:
            showSnackbar?.('error', '');
            break;
        }
      }
      Sentry.captureException(error);
      return new Promise(() => {});
    };

    axiosInstance.interceptors.request.use((config) => {
      const accessToken = storage.getToken();
      config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : '';
      return config;
    });

    const interceptor = axiosInstance.interceptors.response.use(resInterceptor, errInterceptor);
    return () => axiosInstance.interceptors.response.eject(interceptor);
  }, [navigate, showSnackbar]);
  return children;
};
