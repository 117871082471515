import { Box } from '@mui/material';

import { HeaderNavi } from '@/components/Header';
import { SideMenu } from '@/components/SideMenu';

type MainLayoutProps = {
  children: React.ReactNode;
};

export const MainLayout = (props: MainLayoutProps) => {
  const { children } = props;
  return (
    <Box
      id="main-layout-area"
      sx={{
        display: 'flex',
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        background: '#FFFFFF',
      }}
    >
      <HeaderNavi />
      <Box sx={{ marginTop: '101px' }}>
        <SideMenu />
      </Box>
      <Box id="content-layout-area" sx={{ marginTop: '101px' }}>
        <Box component="main">{children}</Box>
      </Box>
    </Box>
  );
};
