import { TreeGroupItem } from './TreeGroup';

export const SideMenuItems: TreeGroupItem[] = [
  {
    height: '192px',
    rootItem: {
      nodeId: '01',
      name: '企業',
    },
    famiryItems: [
      {
        nodeId: '0101',
        name: 'チーム管理',
        childItems: [
          {
            nodeId: '010101',
            name: 'チーム一覧',
            to: '/teamList',
          },
        ],
      },
      {
        nodeId: '0102',
        name: '企業担当者管理',
        childItems: [
          {
            nodeId: '010201',
            name: '企業担当者一覧',
            to: '/companyList',
          },
        ],
      },
    ],
  },
  {
    height: '192px',
    rootItem: {
      nodeId: '02',
      name: '案件',
    },
    famiryItems: [
      {
        nodeId: '0201',
        name: '案件管理',
        childItems: [
          {
            nodeId: '020101',
            name: '案件一覧',
            to: '/projectList',
          },
        ],
      },
      {
        nodeId: '0202',
        name: '制作管理',
        childItems: [
          {
            nodeId: '020101',
            name: 'チーム一覧',
            to: '/productionTeamList',
          },
        ],
      },
    ],
  },
  {
    height: '126px',
    rootItem: {
      nodeId: '03',
      name: 'インフルエンサー',
    },
    famiryItems: [
      {
        nodeId: '0301',
        name: 'インフルエンサー管理',
        childItems: [
          {
            nodeId: '030101',
            name: 'インフルエンサー一覧',
            to: '/influencerList',
          },
        ],
      },
    ],
  },
  {
    height: '159px',
    rootItem: {
      nodeId: '04',
      name: 'お知らせ管理',
    },
    famiryItems: [
      {
        nodeId: '0401',
        name: 'お知らせ',
        childItems: [
          {
            nodeId: '040101',
            name: '企業',
            to: '/companyNoticeList',
          },
          {
            nodeId: '040102',
            name: '企業バナー',
            to: '/companyBannerNoticeList',
          },
          {
            nodeId: '040103',
            name: 'インフルエンサー',
            to: '/influencerNoticeList',
          },
        ],
      },
    ],
  },
  {
    height: '159px',
    rootItem: {
      nodeId: '05',
      name: '請求出金管理',
    },
    famiryItems: [
      {
        nodeId: '0501',
        name: '請求出金管理',
        childItems: [
          {
            nodeId: '050101',
            name: '企業',
            to: '/companyBilling',
          },
          {
            nodeId: '050102',
            name: 'インフルエンサー',
            to: '/influencerBilling',
          },
        ],
      },
    ],
  },
  {
    height: '159px',
    rootItem: {
      nodeId: '06',
      name: 'インフィード広告管理',
    },
    famiryItems: [
      {
        nodeId: '0601',
        name: 'インフィード広告管理',
        childItems: [
          {
            nodeId: '060101',
            name: 'インフィード広告一覧',
            to: '/infeedAdList',
          },
          {
            nodeId: '060102',
            name: '新規インフィード広告入稿',
            to: '/infeedAdCreate',
          },
        ],
      },
    ],
  },
];
