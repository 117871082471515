import { Navigate, Outlet } from 'react-router-dom';

import { MainLayout } from '@/components/Layout';
import { Login } from '@/features/auth';
import { lazyImport } from '@/utils/lazyImport';

const { Top } = lazyImport(() => import('@/features/top'), 'Top');
const { TeamList } = lazyImport(() => import('@/features/teamList'), 'TeamList');
const { TeamDetail } = lazyImport(() => import('@/features/teamDetail'), 'TeamDetail');
const { CompanyList } = lazyImport(() => import('@/features/companyList'), 'CompanyList');
const { CompanyDetail } = lazyImport(() => import('@/features/companyDetail'), 'CompanyDetail');
const { ProjectList } = lazyImport(() => import('@/features/projectList'), 'ProjectList');
const { ProjectDetail } = lazyImport(() => import('@/features/projectDetail'), 'ProjectDetail');
const { ProjectInfluencerDetail } = lazyImport(
  () => import('@/features/projectInfluencerDetail'),
  'ProjectInfluencerDetail'
);
const { InfluencerList } = lazyImport(() => import('@/features/influencerList'), 'InfluencerList');
const { InfluencerDetail } = lazyImport(() => import('@/features/influencerDetail'), 'InfluencerDetail');
const { CompanyNoticeList } = lazyImport(() => import('@/features/companyNoticeList'), 'CompanyNoticeList');
const { CompanyNoticeUpdate } = lazyImport(() => import('@/features/companyNoticeEdit'), 'CompanyNoticeUpdate');
const { AccountDetailTiktok } = lazyImport(
  () =>
    import(
      '@/features/projectDetail/components/influencerApplicant/spirit/tiktokApplicant/tiktokPostInfo/AccountDetailTiktok'
    ),
  'AccountDetailTiktok'
);
const { CompanyBannerNoticeList } = lazyImport(
  () => import('@/features/companyBannerNoticeList'),
  'CompanyBannerNoticeList'
);
const { CompanyBannerNoticeCreate } = lazyImport(
  () => import('@/features/companyBannerNoticeEdit'),
  'CompanyBannerNoticeCreate'
);
const { CompanyBannerNoticeUpdate } = lazyImport(
  () => import('@/features/companyBannerNoticeEdit'),
  'CompanyBannerNoticeUpdate'
);
const { InfluencerNoticeList } = lazyImport(() => import('@/features/influencerNoticeList'), 'InfluencerNoticeList');
const { InfluencerNoticeUpdate } = lazyImport(
  () => import('@/features/influencerNoticeEdit'),
  'InfluencerNoticeUpdate'
);

const { CompanyBillingWithdrawal } = lazyImport(
  () => import('@/features/billingWithdrawal'),
  'CompanyBillingWithdrawal'
);
const { InfluencerBillingWithdrawal } = lazyImport(
  () => import('@/features/billingWithdrawal'),
  'InfluencerBillingWithdrawal'
);
const { InfeedAdList } = lazyImport(() => import('@/features/infeedAdList'), 'InfeedAdList');
const { InfeedAdCreate } = lazyImport(() => import('@/features/infeedAdEdit'), 'InfeedAdCreate');
const { InfeedAdUpdate } = lazyImport(() => import('@/features/infeedAdEdit'), 'InfeedAdUpdate');
const { ProductionTeamList } = lazyImport(() => import('@/features/productionTeamList'), 'ProductionTeamList');
const { ProductionProjectList } = lazyImport(() => import('@/features/productionProjectList'), 'ProductionProjectList');

const App = () => {
  return (
    <Login isHideSignUp>
      <MainLayout>
        <Outlet />
      </MainLayout>
    </Login>
  );
};

export const publicRoutes = [
  {
    path: '/',
    element: <App />,
    children: [
      { index: true, element: <Top /> },
      { path: '/teamList', element: <TeamList /> },
      { path: '/teamDetail/:teamId', element: <TeamDetail /> },
      { path: '/companyList', element: <CompanyList /> },
      { path: '/companyDetail/:userId', element: <CompanyDetail /> },
      { path: '/projectList', element: <ProjectList /> },
      { path: '/projectDetail/:projectId', element: <ProjectDetail /> },
      { path: '/projectInfluencerDetail/:influencerId', element: <ProjectInfluencerDetail /> },
      { path: '/influencerList', element: <InfluencerList /> },
      { path: '/influencerDetail/:influencerId', element: <InfluencerDetail /> },
      { path: '/companyNoticeList', element: <CompanyNoticeList /> }, // お知らせ管理(企業)
      { path: '/companyNoticeUpdate/:noticeId', element: <CompanyNoticeUpdate /> }, // お知らせ管理（企業）編集
      { path: '/companyBannerNoticeList', element: <CompanyBannerNoticeList /> }, // お知らせ管理(バナー)
      { path: '/companyBannerNoticeUpdate/:adId', element: <CompanyBannerNoticeUpdate /> }, // お知らせ管理(企業バナー) 新規登録
      { path: '/companyBannerNoticeCreate', element: <CompanyBannerNoticeCreate /> }, // お知らせ管理(企業バナー) 編集
      { path: '/influencerNoticeList', element: <InfluencerNoticeList /> }, // お知らせ管理(インフルエンサー)
      { path: '/influencerNoticeUpdate/:noticeId', element: <InfluencerNoticeUpdate /> }, // お知らせインフルエンサー
      { path: '/companyBilling', element: <CompanyBillingWithdrawal /> },
      { path: '/influencerBilling', element: <InfluencerBillingWithdrawal /> },
      { path: '/infeedAdList', element: <InfeedAdList /> },
      { path: '/infeedAdUpdate/:adId', element: <InfeedAdUpdate /> },
      { path: '/infeedAdCreate', element: <InfeedAdCreate /> },
      { path: '/productionTeamList', element: <ProductionTeamList /> },
      { path: '/productionProjectList/:teamId', element: <ProductionProjectList /> },
      { path: '/tiktokPostInfoBody', element: <AccountDetailTiktok /> },
      { path: '*', element: <Navigate to="." /> },
    ],
  },
];
