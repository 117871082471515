import { Box } from '@mui/material';

import { SearchButton } from '@/components/Elements/Button';

type SearchFormLayoutProps = {
  outerWidth: string;
  outerHeight: string;
  innerWidth: string;
  innerHeight: string;
  handleOnSubmit: () => void;
  children: React.ReactNode;
};

export const SearchFormLayout = (props: SearchFormLayoutProps) => {
  const { outerWidth, outerHeight, innerWidth, innerHeight, handleOnSubmit, children } = props;

  return (
    <Box
      sx={{
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        padding: ['20px', '30px', '0px'],
        width: outerWidth,
        height: outerHeight,
        border: '1px',
        borderStyle: 'solid',
        borderColor: 'rgba(0, 0, 0, 0.16)',
      }}
    >
      <form>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: '0px',
            gap: '5px',
            width: innerWidth,
            height: innerHeight,
            marginTop: '27px',
            marginLeft: '30px',
          }}
        >
          {children}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <SearchButton handleOnSubmit={handleOnSubmit} />
        </Box>
      </form>
    </Box>
  );
};
