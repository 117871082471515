import { TreeItem, treeItemClasses } from '@mui/lab';
import { Typography, styled, Box } from '@mui/material';
import { NavigateFunction, To } from 'react-router';

type TreeItemRoot = {
  nodeId: string;
  name: string;
  to?: To;
};

type TreeItemFamiry = TreeItemRoot & {
  childItems: TreeItemRoot[];
};

export type TreeGroupItem = {
  height: string;
  rootItem: TreeItemRoot;
  famiryItems: TreeItemFamiry[];
};

export type TreeGroupProps = {
  treeGroupItem: TreeGroupItem;
  navigate: NavigateFunction;
};

const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.group}`]: {
    marginLeft: '0px',
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(1),
    },
  },
}));

export const TreeGroup = (props: TreeGroupProps) => {
  const { navigate, treeGroupItem } = props;
  const handleClick = (to: To | undefined) => {
    if (to) {
      navigate(to);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '0px',
        gap: '5px',
        height: treeGroupItem.height,
      }}
    >
      <Typography
        variant="h5"
        component="div"
        sx={{ width: '270px', height: '52px', paddingTop: '10px', paddingLeft: '10px', fontWeight: 600 }}
      >
        {treeGroupItem.rootItem.name}
      </Typography>
      {treeGroupItem.famiryItems.map((parentItem: TreeItemFamiry) => {
        return (
          <StyledTreeItem
            key={parentItem.nodeId}
            nodeId={parentItem.nodeId}
            label={
              <Typography variant="h6" component="div" sx={{ paddingTop: '4px', paddingLeft: '4px' }}>
                {parentItem.name}
              </Typography>
            }
            sx={{ width: '270px' }}
          >
            {parentItem.childItems.map((childItem: TreeItemRoot) => {
              return (
                <StyledTreeItem
                  key={childItem.nodeId}
                  nodeId={childItem.nodeId}
                  onClick={() => handleClick(childItem.to)}
                  label={
                    <Typography variant="body1" component="div" sx={{ paddingTop: '4px', paddingLeft: '2px' }}>
                      {childItem.name}
                    </Typography>
                  }
                />
              );
            })}
          </StyledTreeItem>
        );
      })}
    </Box>
  );
};
