import * as React from 'react';
// named imports for React.lazy: https://github.com/facebook/react/issues/14603#issuecomment-726551598
export function lazyImport<T extends React.ComponentType<unknown>, I extends { [K2 in K]: T }, K extends keyof I>(
  factory: () => Promise<I>,
  name: K
): { [K2 in K]: React.LazyExoticComponent<T> } {
  return {
    [name]: React.lazy(() => factory().then((module) => ({ default: module[name] }))),
  } as unknown as { [K2 in K]: React.LazyExoticComponent<T> };
}

// Usage
// const { Home } = lazyImport(() => import("./Home"), "Home");
