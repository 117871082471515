import { Box, Breadcrumbs, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

type BreadcrumbsItem = {
  name: string;
  to: string;
};

export type ContentBreadcrumbsProps = {
  breadcrumbsItems: BreadcrumbsItem[];
};

export const ContentBreadcrumbs = (props: ContentBreadcrumbsProps) => {
  const { breadcrumbsItems } = props;
  const itemLength = breadcrumbsItems.length;
  const lastItemIndex = itemLength > 0 ? itemLength - 1 : 0;
  const linkStyle = {
    textDecoration: 'none',
  };
  return (
    <Box>
      <Breadcrumbs aria-label="breadcrumb">
        {breadcrumbsItems.map((item: BreadcrumbsItem, index: number) => {
          const id = `_${index}`;
          if (index < lastItemIndex) {
            return (
              <Link key={id} to={item.to} style={linkStyle}>
                <Typography variant="body1" sx={{ color: '#0288D1' }}>
                  {item.name}
                </Typography>
              </Link>
            );
          }
          return (
            <Typography key={id} variant="body1" sx={{ color: '#000000' }}>
              {item.name}
            </Typography>
          );
        })}
      </Breadcrumbs>
    </Box>
  );
};
