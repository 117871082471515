import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { TreeView } from '@mui/lab';
import { Box, Link } from '@mui/material';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router';

import { SideMenuItems } from './SideMenuItems';
import { TreeGroup, TreeGroupItem } from './TreeGroup';

export const SideMenu = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ width: '310px', height: '1315px', background: '#DEDEDE' }}>
      <TreeView
        aria-label="multi-select"
        defaultCollapseIcon={<ExpandMore />}
        defaultExpandIcon={<ChevronRight />}
        multiSelect
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '25px',
          paddingTop: '10px',
          paddingLeft: '20px',
        }}
      >
        {SideMenuItems.map((item: TreeGroupItem, index: number) => {
          const id = index;
          return <TreeGroup key={id} treeGroupItem={item} navigate={navigate} />;
        })}
        <Link component="button" underline="none" onClick={() => Auth.signOut()}>
          ログアウト
        </Link>
      </TreeView>
    </Box>
  );
};
