import { useRoutes } from 'react-router-dom';

import { publicRoutes } from './public';

export const AppRoutes = () => {
  // TODO: AWSのAuthでログイン状態を判定
  // const routes = auth.user ? protectedRoutes : publicRoutes;
  const routes = publicRoutes;

  const element = useRoutes(routes);

  return <>{element}</>;
};
